import { PhPayApiEndpoints } from '@/api/phPayApiClient/endpoints';
import fetch from './base';
import { AddGiftCard, PaymentTypes } from '@/api/phPayApiClient/types';
import { giftCardInfoFromSavedGiftCard } from '@/domain/payments/types';

const addGiftCard: AddGiftCard = async (giftcard) => {
  const {
    cardNumber,
    pin
  } = giftcard;

  let response: Response;

  try {
    response = await fetch(PhPayApiEndpoints.ADD_GIFT_CARD, {
      method: 'POST',
      body: JSON.stringify({
        type: PaymentTypes.GiftCard,
        metadata: {
          number: cardNumber,
          pin
        }
      })
    });
  } catch (e) {
    return { error: true };
  }
  // 299+; redirection, client/server err
  if (response.status > 299) {
    return { error: true };
  }

  const cardJson = await response.json();

  return {
    error: false,
    result: giftCardInfoFromSavedGiftCard(
      cardJson.payment_id,
      cardJson.metadata.number,
      cardJson.metadata.balance
    )
  };
};

export {
  addGiftCard
};
