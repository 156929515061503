import phdApiClient from '@/api/phdApiClient';
import { EditLoginDataPayload } from '@/services/accountManagementService/phdApiAccountManagementService/types';
import { Profile } from '@/domain/Profile';
import { IncorrectPasswordError, token as escalateSuccess } from '@/api/phdApiClient/accountManagement/escalatePrivileges';
import { PasswordComplexityError, success as updatePasswordSuccess } from '@/api/phdApiClient/accountManagement/myAccount/updatePassword';

const changingPassword = (
  payload: EditLoginDataPayload
): payload is Required<EditLoginDataPayload> => typeof payload.oldPassword === 'string'
    && typeof payload.newPassword === 'string'
    && payload.oldPassword !== ''
    && payload.newPassword !== '';

const updateCustomer = async (editLoginDataPayload: EditLoginDataPayload):
Promise<ClientResult<Profile | PasswordComplexityError | IncorrectPasswordError | Error>> => {
  const updateCustomerResult = await phdApiClient.myAccount.updateCustomer(editLoginDataPayload);

  if (!changingPassword(editLoginDataPayload) || updateCustomerResult.error) {
    return updateCustomerResult;
  }
  const { email, oldPassword, newPassword } = editLoginDataPayload;

  // escalate privileges
  const escalate = await phdApiClient.myAccount.escalatePrivileges(oldPassword, email);
  if (!escalateSuccess(escalate)) {
    return { error: true, result: escalate };
  }
  const { token } = escalate;

  // change password
  const updatePassword = await phdApiClient.myAccount.updatePassword(token, newPassword);
  if (!updatePasswordSuccess(updatePassword)) {
    return { error: true, result: updatePassword };
  }

  return updateCustomerResult;
};

export default updateCustomer;
