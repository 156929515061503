import { mockFunction } from '@/testUtils/mockFunction';
import { tokenizeCard as mockTokenizeCardBody } from './tokenizeCard.stub';
import { getThirdPartyGiftCardBalance as mockThirdPartyGetGiftCardBalance } from './getThirdPartyGiftCardBalance.stub';
import { PhPayThirdPartyGiftCardBody } from '@/api/phPayApiClient/types';
import { addCreditCard } from './addPayment';

export interface PhPayMockedResponses {
  tokenizeCard: () => Promise<Response>;
  getThirdPartyGiftCardBalance: (card: Partial<PhPayThirdPartyGiftCardBody>) => Promise<Response>;
  addCreditCard: typeof addCreditCard;
}

export const makePhPayApiClientMock: PhPayMockedResponses = {
  tokenizeCard: mockFunction(mockTokenizeCardBody),
  getThirdPartyGiftCardBalance: mockFunction(mockThirdPartyGetGiftCardBalance),
  addCreditCard
};

export const phPayApiClient = makePhPayApiClientMock;
