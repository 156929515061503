import { tokenizeCard } from './tokenizeCard';
import {
  AddCardError,
  AddCreditCard,
  AddThirdPartyGiftCard, ApiPaymentId,
  ExpiredCardError,
  PaymentTypes
} from '@/api/phPayApiClient/types';
import { PhPayApiEndpoints } from '@/api/phPayApiClient/endpoints';
import fetch from './base';
import {
  CreditCard,
  EditedCreditCard,
  GiftCardInfo,
  giftCardInfoFromTpgc,
  SavedCreditCard
} from '@/domain/payments/types';
import { getCardType } from '@/api/phPayApiClient/helpers/getCardType';
import logger from '@/common/logger';
import telemetry from '@/telemetry';

export const isExpiredCreditCard = (
  result: ClientResult<SavedCreditCard | ApiPaymentId | AddCardError>
): result is ClientResult<ExpiredCardError> => Boolean(
  result && result.result && 'reason' in result.result && result.result.reason === 'expired'
);

const expiredCreditCardResponse = async (response: Response): Promise<boolean> => Boolean(
  response.status === 400 && response.json && (await response.json()).error_code === '0348'
);

const supportedCardTypes = ['MC', 'VISA', 'DISC', 'AMEX', 'DEBIT'];

const unsupportedCardType = (cardNumber: CreditCard['cardNumber']): boolean => !supportedCardTypes.includes(getCardType(cardNumber));

export const isCreditCardApiSuccess = (
  result: ApiPaymentId | AddCardError | undefined
): result is ApiPaymentId => typeof result !== 'undefined' && !('reason' in result);

export const isSavedCreditCard = (
  result: SavedCreditCard | EditedCreditCard
): result is SavedCreditCard => !('cardInfo' in result);

const addCreditCard: AddCreditCard = async (card) => {
  const {
    cardNumber,
    expirationDate,
    postalCode,
    primary,
    name,
    cardMask
  } = card;
  if (unsupportedCardType(cardNumber)) {
    const cardTypeError = new Error('Unsupported credit card');
    logger.withoutTelemetry.error(String(cardTypeError), { type: getCardType(cardNumber) });
    telemetry.addNoticeError(cardTypeError, { type: getCardType(cardNumber) });
    return { error: true, result: { reason: 'unsupported' } };
  }

  const {
    error: tokenizationError,
    result: token
  } = await tokenizeCard({ cardNumber, expirationDate });

  if (tokenizationError) {
    const errorMessage = 'Error tokenizing credit card';
    logger.withoutTelemetry.error(errorMessage);
    telemetry.addNoticeError(new Error(errorMessage));
    return { error: true, result: { reason: 'tokenization' } };
  }

  let response: Response;
  try {
    response = await fetch(PhPayApiEndpoints.ADD_CREDIT_CARD, {
      method: 'POST',
      body: JSON.stringify({
        is_default: primary,
        name,
        type: PaymentTypes.Card,
        metadata: {
          type: getCardType(cardNumber),
          card_mask: cardMask,
          expiration: expirationDate,
          postal_code: postalCode,
          gateway: 'VERIFONE',
          token
        }
      })
    });
  } catch (err) {
    return { error: true };
  }
  if (await expiredCreditCardResponse(response)) {
    return { error: true, result: { reason: 'expired' } };
  }
  if (response.status > 299) {
    return { error: true };
  }
  const res = await response.json();
  return { error: false, result: { ...card, paymentId: res.payment_id } };
};

const addThirdPartyGiftCard: AddThirdPartyGiftCard = async (tpgc) => {
  const {
    cardNumber,
    expirationDate,
    cvv,
    cardMask
  } = tpgc;

  if (unsupportedCardType(cardNumber)) {
    return { error: true, result: { reason: 'unsupported' } };
  }

  const {
    error: tokenizationError,
    result: token
  } = await tokenizeCard({ cardNumber, expirationDate });

  if (tokenizationError) {
    return { error: true, result: { reason: 'tokenization' } };
  }

  let response: Response;
  try {
    response = await fetch(PhPayApiEndpoints.ADD_GIFT_CARD, {
      method: 'POST',
      body: JSON.stringify({
        type: PaymentTypes.TPGC,
        metadata: {
          type: getCardType(cardNumber),
          card_mask: cardMask,
          card_code: cvv,
          expiration: expirationDate,
          gateway: 'VERIFONE',
          token
        }
      })
    });
  } catch (err) {
    return { error: true };
  }
  if (await expiredCreditCardResponse(response)) {
    return { error: true, result: { reason: 'expired' } };
  }
  if (response.status > 299) {
    return { error: true };
  }
  const cardJson = await response.json();
  return {
    error: false,
    result: giftCardInfoFromTpgc(cardJson.payment_id, cardJson.metadata.number, 0)
  };
};

const addableGiftCard = (
  response: GiftCardInfo | AddCardError | undefined
): response is GiftCardInfo => Boolean(response && 'paymentType' in response);

export {
  addCreditCard,
  addThirdPartyGiftCard,
  addableGiftCard
};
