import { getPhPayUrl } from '@/api/phPayApiClient/getPhPayUrl';
import { PhPayApiEndpoints } from '@/api/phPayApiClient/endpoints';
import loggingFetch from '@/common/loggingFetch';
import { getPublicRuntimeConfig } from '../phdApiClient/helpers/getPublicRuntimeConfig';

const commonHeaders = (): Record<string, string> => {
  const { phdApiServerEnv } = getPublicRuntimeConfig();
  return {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Auth-Channel': 'WEB2',
    'X-Server-Env': phdApiServerEnv
  };
};

const fetch = (
  endpoint: PhPayApiEndpoints,
  originalOptions: RequestInit = {}
): Promise<Response> => {
  const url = getPhPayUrl(endpoint);
  const options: RequestInit = {
    ...originalOptions,
    headers: {
      ...originalOptions.headers,
      ...commonHeaders()
    },
    credentials: 'include'
  };

  return loggingFetch(url, options);
};

export default fetch;
