import { EditCreditCard } from '@/services/accountManagementService/phdApiAccountManagementService/types';
import { PhPayApiEndpoints } from './endpoints';
import {
  PaymentTypes
} from '@/api/phPayApiClient/types';
import { EditedCreditCard } from '@/domain/payments/types';
import fetch from './base';

const editCreditCard: EditCreditCard = async (card: EditedCreditCard): Promise<ClientResult<EditedCreditCard>> => {
  const {
    paymentId,
    cardInfo,
    isDefault,
    name
  } = card;

  const url = `${PhPayApiEndpoints.EDIT_CREDIT_CARD}/${paymentId}`;
  let response: Response;
  try {
    response = await fetch((url as PhPayApiEndpoints), {
      method: 'PUT',
      body: JSON.stringify({
        name,
        type: PaymentTypes.Card,
        is_default: isDefault,
        metadata: {
          postal_code: cardInfo.postalCode,
          expiration: cardInfo.expiration,
          gateway: 'VERIFONE'
        }
      })
    });
  } catch (err) {
    return { error: true };
  }
  return {
    error: false,
    result: card
  };
};

export default editCreditCard;
