import { PhPayThirdPartyGiftCardBody, PhPayGiftCardResponse } from '@/api/phPayApiClient/types';
import { transformDollarToCents } from '@/api/phPayApiClient/helpers/transformDollarsToCents';

export const expirationDateIsNotSixDigitNumber = (
  expirationDate: string | undefined
): boolean => expirationDate?.length !== 6 || Number.isNaN(Number(expirationDate));

export const getBalance = (card: Partial<PhPayThirdPartyGiftCardBody>): number | undefined => parseFloat('100.00');

export const mockGiftCardBalanceApiResponse = (
  card: Partial<PhPayThirdPartyGiftCardBody>
): PhPayGiftCardResponse => {
  if (expirationDateIsNotSixDigitNumber(card.expirationDate)) {
    return {
      error_code: '400',
      message: 'expiration date format invalid'
    };
  }

  const balance = getBalance(card);

  if (!Number.isNaN(balance)) {
    return {
      balance
    };
  }

  return {
    error_code: 'card.not.found',
    message: 'Business Exception occurred while performing the operation'
  };
};

export const mockGiftCardBalanceTransformedResponse = (
  card: Partial<PhPayThirdPartyGiftCardBody>
): ClientResult<PhPayGiftCardResponse> => {
  if (expirationDateIsNotSixDigitNumber(card.expirationDate)) {
    return ({ error: true });
  }

  const balance = getBalance(card);

  if (balance) {
    return {
      result: { balance: transformDollarToCents(balance) },
      error: false
    };
  }

  return { error: true };
};

export const getThirdPartyGiftCardBalance = async (
  card: Partial<PhPayThirdPartyGiftCardBody>
): Promise<Response> => (
  Promise.resolve(new Response(JSON.stringify(mockGiftCardBalanceApiResponse(card))))
);
