import { getPhPayUrl } from './getPhPayUrl';
import { PhPayApiEndpoints } from './endpoints';
import { post } from '../clientHelpers';
import {
  TokenizeCard,
  PhPayTokenizeCardBody,
  PhPayTokenizeCardResult,
  PhPayTokenizeCardArgs
} from './types';
import { getNumericValue } from '@/checkout/helpers/getNumericValue';
import { makePhPayApiClientMock } from '@/api/phPayApiClient/__mocks__';
import telemetry from '@/telemetry';
import { TOKENIZE_ERROR_MSG } from '@/api/phPayApiClient/tokenizeConstants';
import { getPublicRuntimeConfig } from '../phdApiClient/helpers/getPublicRuntimeConfig';

type TransformTokenizeCardBodyArgs = {
  cardNumber: string;
  expirationDate: string;
};

const transformTokenizeCardBody = ({ cardNumber, expirationDate }: TransformTokenizeCardBodyArgs)
: PhPayTokenizeCardBody => {
  const parsedCardNumber = getNumericValue(cardNumber);
  const [expirationMonth, expirationYear] = expirationDate.split('/');

  return {
    expiration_month: parseInt(expirationMonth, 10),
    expiration_year: parseInt(expirationYear, 10),
    pan: parsedCardNumber
  };
};

const transformTokenizedCardInfo = (response: PhPayTokenizeCardResult): string => response.token;

export const tokenizeCard: TokenizeCard = async ({
  cardNumber,
  expirationDate
}: PhPayTokenizeCardArgs): Promise<ClientResult<string>> => {
  const phPayUrl = getPhPayUrl(PhPayApiEndpoints.TOKENIZE_CARD);
  const { phdApiServerEnv } = getPublicRuntimeConfig();
  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Auth-Channel': 'WEB2',
    'X-Server-Env': phdApiServerEnv
  };
  const body = transformTokenizeCardBody({ cardNumber, expirationDate });

  return post(phPayUrl, headers, body, makePhPayApiClientMock.tokenizeCard)
    .then((response) => response.json())
    .then(transformTokenizedCardInfo)
    .then((result) => ({
      error: false,
      result
    }))
    .catch((e) => {
      telemetry.addNoticeError(new Error(TOKENIZE_ERROR_MSG));
      return { error: true };
    });
};
