export const token = 'someRandomStringWithLettersAnd123Numbers';
export const mockTokenizeCardApiResponse = { token };

export const mockTokenizeCardTransformedResponse = {
  error: false,
  result: token
};

export const tokenizeCard = async (): Promise<Response> => (
  Promise.resolve(new Response(JSON.stringify(mockTokenizeCardApiResponse)))
);
