import { tokenizeCard } from './tokenizeCard';
import { getThirdPartyGiftCardBalance } from './getThirdPartyGiftCardBalance';
import { addCreditCard, addThirdPartyGiftCard } from './addPayment';
import editCreditCard from './editPayment';
import { PhPayApiClient } from './types';
import { addGiftCard } from '@/api/phPayApiClient/addGiftCard';

const makePhPayApiClientImpl = (): PhPayApiClient => ({
  tokenizeCard,
  getThirdPartyGiftCardBalance,
  addCreditCard,
  editCreditCard,
  addGiftCard,
  addThirdPartyGiftCard
});

export const phPayApiClient: PhPayApiClient = makePhPayApiClientImpl();
