import phdApiClient from '@/api/phdApiClient';
import { Favorite } from '@/account/orders/favorites/Favorites.types';
import {
  EditFavoriteResult
} from '@/services/accountManagementService/phdApiAccountManagementService/types';

const renameFavorite = async (
  favorite: Favorite, newName: string
): Promise<{ serverChangedName: boolean; error: boolean }> => {
  const status = {
    serverChangedName: false,
    error: false
  };

  if (newName === favorite.name) return status;

  const editNameResponse = await phdApiClient.favorites.editFavorite(favorite.id, newName);
  status.serverChangedName = !editNameResponse.error;
  status.error = editNameResponse.error;

  return status;
};

const makeFavoritePrimary = async (
  favorite: Favorite, favorites: Favorite[]
): Promise<{ serverMadePrimary: boolean; error: boolean }> => {
  const makePrimaryResponse = await phdApiClient
    .favorites
    .rankFavorites(favorite, favorites);

  return {
    serverMadePrimary: !makePrimaryResponse.error,
    error: makePrimaryResponse.error
  };
};

async function editFavorite(
  favorite: Favorite,
  newName: string,
  favorites?: Favorite[],
  makeFavPrimary?: boolean
): Promise<ClientResult<EditFavoriteResult>> {
  const changeNameResult = await renameFavorite(favorite, newName);
  const returnedFavorite = changeNameResult.serverChangedName
    ? { ...favorite, name: newName } : favorite;

  let makePrimaryResult = { serverMadePrimary: false, error: false };
  if (makeFavPrimary) {
    makePrimaryResult = await makeFavoritePrimary(favorite, favorites || []);
  }

  return {
    error: changeNameResult.error || makePrimaryResult.error,
    result: {
      favorite: returnedFavorite,
      changedName: changeNameResult.serverChangedName,
      madePrimary: makePrimaryResult.serverMadePrimary
    }
  };
}

export default editFavorite;
