import { getPhPayUrl } from './getPhPayUrl';
import { PhPayApiEndpoints } from './endpoints';
import { post } from '../clientHelpers';
import { PhPayThirdPartyGiftCardBody, PhPayGiftCardResponse } from './types';
import { GiftCardBalance } from '@/checkout/types';
import { transformDollarToCents } from './helpers/transformDollarsToCents';
import { getPublicRuntimeConfig } from '../phdApiClient/helpers/getPublicRuntimeConfig';

const transformGiftCardResponse = (response: PhPayGiftCardResponse): GiftCardBalance => ({
  balance: transformDollarToCents(Number(response.balance))
});

export const getThirdPartyGiftCardBalance = async (
  body: PhPayThirdPartyGiftCardBody,
  mockFunction: () => Promise<Response>
): Promise<ClientResult<GiftCardBalance>> => {
  const phPayUrl = getPhPayUrl(PhPayApiEndpoints.THIRD_PARTY_GIFT_CARD_BALANCE);
  const { phdApiServerEnv } = getPublicRuntimeConfig();

  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'Auth-Channel': 'WEB2',
    'X-Server-Env': phdApiServerEnv
  };

  return post(phPayUrl, headers, body, mockFunction)
    .then((response) => response.json())
    .then(transformGiftCardResponse)
    .then((result) => ({
      error: false,
      result
    }))
    .catch(() => ({
      error: true
    }));
};
