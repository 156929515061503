import phdApiClient from '@/api/phdApiClient';
import { AddCustomerAddressPayload } from '../types';
import { ProfileAddress } from '@/domain/Profile';

const addCustomerAddress = (
  addCustomerAddressPayload: AddCustomerAddressPayload,
  firstAddress = false
)
: Promise<ClientResult<ProfileAddress>> => {
  if (firstAddress) {
    return phdApiClient.myAccount.addCustomerAddress(
      { ...addCustomerAddressPayload, is_default: true }
    );
  }
  return phdApiClient.myAccount.addCustomerAddress(addCustomerAddressPayload);
};
export default addCustomerAddress;
