import { SavedCreditCard } from '@/domain/payments/types';

const addCreditCard = typeof jest === 'undefined' ? () => {} : jest.fn();
const addThirdPartyGiftCard = typeof jest === 'undefined' ? () => {} : jest.fn();

const successfulAddCreditCard = (card: SavedCreditCard): void => {
  if (!('mockResolvedValue' in addCreditCard) || typeof addCreditCard.mockResolvedValue !== 'function') {
    throw Error('Trying to use a mock behavior setter outside of test environment');
  }
  addCreditCard.mockResolvedValue({
    error: false,
    result: card
  });
};

const successfulAddThirdPartyGiftcard = (card: SavedCreditCard): void => {
  if (!('mockResolvedValue' in addThirdPartyGiftCard) || typeof addThirdPartyGiftCard.mockResolvedValue !== 'function') {
    throw Error('Trying to use a mock behavior setter outside of test environment');
  }
  addThirdPartyGiftCard.mockResolvedValue({
    error: false,
    result: card
  });
};

export {
  // Mock functions
  addCreditCard,
  addThirdPartyGiftCard,

  // Behavior mocking
  successfulAddCreditCard,
  successfulAddThirdPartyGiftcard
};
