import phdApiClient from '@/api/phdApiClient';
import { EditCustomerAddressPayload } from '../types';
import { ProfileAddress } from '@/domain/Profile';

const editCustomerAddress = (
  editCustomerAddressPayload: EditCustomerAddressPayload
)
: Promise<ClientResult<ProfileAddress>> => phdApiClient.myAccount.editCustomerAddress(
  editCustomerAddressPayload
);
export default editCustomerAddress;
