import getConfig from 'next/config';
import { PhPayApiEndpoints } from './endpoints';

const getPublicRuntimeConfig = () => {
  const { publicRuntimeConfig } = getConfig();
  return {
    phPayApiUrl: publicRuntimeConfig.PH_PAY_URL,
    phPayApiKey: publicRuntimeConfig.PH_PAY_API_KEY,
    enableMockServer: String(publicRuntimeConfig.ENVIRONMENT_NAME) !== 'prod'
  };
};

export const getPhPayUrl = (endpoint: PhPayApiEndpoints): string => {
  const { phPayApiUrl, phPayApiKey, enableMockServer } = getPublicRuntimeConfig();

  if (endpoint === PhPayApiEndpoints.THIRD_PARTY_GIFT_CARD_BALANCE && enableMockServer) {
    return '/api/stubs/gift-card-balance';
  }

  return `${phPayApiUrl}${endpoint}?key=${phPayApiKey}`;
};
