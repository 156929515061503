import phdApiClient from '@/api/phdApiClient';
import { OrderHistoryType } from '@/account/orders/orderHistory/OrderHistory.types';

export default async function getOrders() : Promise<OrderHistoryType | undefined> {
  const ordersResponse = await phdApiClient.getOrders();

  if (ordersResponse.error || !ordersResponse.result) {
    return undefined;
  }

  return ordersResponse.result;
}
