import { AccountManagementService } from './types';
import getOrders from './orders/getOrders';
import addFavorite from './favorites/addFavorite';
import getFavorites from './favorites/getFavorites';
import editFavorite from './favorites/editFavorite';
import deleteFavorite from './favorites/deleteFavorite';
import getCustomer from './myAccount/getCustomer';
import updateCustomer
  from '@/services/accountManagementService/phdApiAccountManagementService/myAccount/updateCustomer';
import addCustomerAddress from '@/services/accountManagementService/phdApiAccountManagementService/myAccount/addCustomerAddress';
import { addCreditCard } from './myAccount/addCreditCard';
import addGiftCard from './myAccount/addGiftCard';
import addThirdPartyGiftCard from './myAccount/addThirdPartyGiftCard';
import deleteCard from './myAccount/deleteCard';
import { deleteCoupon } from './myAccount/deleteCoupon';
import updatePromotionsContact from './myAccount/updatePromotionsContact';
import deleteAddress from './myAccount/deleteAddress';
import customerLoyalty from './myAccount/customerLoyalty';
import editCustomerAddress from './myAccount/editCustomerAddress';

const phdApiAccountManagementService: AccountManagementService = {
  getOrders,
  favorites: {
    addFavorite,
    getFavorites,
    editFavorite,
    deleteFavorite
  },
  myAccount: {
    getCustomer,
    updateCustomer,
    addCustomerAddress,
    addCreditCard,
    deleteCard,
    deleteCoupon,
    updatePromotionsContact,
    deleteAddress,
    addGiftCard,
    addThirdPartyGiftCard,
    customerLoyalty,
    editCustomerAddress
  }
};

export default phdApiAccountManagementService;
